import React from 'react';

export default () => (
  <section className='container-fluid'>
          <div className='row m-2-hor'>
            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/home.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>We Aim for the Top</div>
                  <div className='content'>
                    We believe that interior design is more than great functionality 
                    and beautiful aesthetics. We aim to make your interiors 
                    a reflection of your personality. Your place should be something 
                    that you and your family/co-workers take pride in and love to spend time in.
                  </div>
                  <ul className='list-home'>
                    <li>Approvals</li>
                    <li>Fit-Out</li>
                    <li>MEP</li>
                    <li>Joinery & Furniture</li>
                    <li>Project Handover</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </section>
);
