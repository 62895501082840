import React from "react";
import { withRouter } from "react-router-dom";
import emailjs from 'emailjs-com';
import Reveal from 'react-reveal/Reveal';

// import SimpleMap from '../components/Map';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Contact = ({ history }) => {

    function sendEmail(e) {
    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('service_ramadinteriors', 'template_k2r7fic', e.target, 'user_dMS1ZQIoT6OjC0MDq4C11')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }  

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>

      <Reveal effect="fadeInUp">
       <section className='container pb-2'>
          <div className='row'>
            <div className='col-12'>
              <h1>Get in touch with us to design <br/>your <span className='color'>dream</span> project</h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container pt-0 pb-2'>
          <div className='row'>
              <div className='col-12'>
                <div class="mapouter">
                  <div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.5549722653677!2d55.60961321460669!3d25.31915188383958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f3f020f2a5df%3A0xe22cea39ea5dafcc!2sAl%20Ramad%20Al%20Thahabi%20Furniture%20Industry%20LLC!5e0!3m2!1sen!2sca!4v1650392141616!5m2!1sen!2sca"
                      frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Factory Location"></iframe></div>
                </div>
              </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
       <section className='container pt-0'>
          <div className='row'>
            {/* <div className='col-12'>
              <SimpleMap/>
            </div> */}
            <div className='col-md-6'>
            <div className="text-side">
              <h3 className="heading">Talk with our expert designers </h3>
              <p>
              We would be delighted to hear from you and make your imagination come to reality.
              Please write to us or call us, our team is ready to answer all your questions.</p>
              <div className='address'>
                <div className='heading'>Connect with Us</div>
                {/* <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  70 Washington Square South New York, NY 10012, United States
                </div> */}
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:info@ramadinteriors.com' target='_blank' rel='noopener noreferrer'>
                    info@ramadinteriors.com
                  </a>
                </div>                
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+97165200441">+971 65 20 0441</a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+971545948669">+971 54 594 8669</a>
                </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail}>
                <label>Name</label>
                <input type="text" name="user_name" required />
                <label>Email</label>
                <input type="email" name="user_email" required />
                <label>Message</label>
                <textarea name="message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
            </div>
          </div>


          </div>
       </section>
      </Reveal>

      

      <Footer />

    </div>
    
  );
};

export default withRouter(Contact);
