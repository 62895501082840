import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';
import Slider from '../components/Sliderhome';
import Abouthome from '../components/Abouthome';
import Carouselfeature from '../components/Carouselfeature';
// import Carouselclient from '../components/Carouselclient';
// import Carouselclientb from '../components/Carouselclientb';
// import Carouselprojects from '../components/Carouselprojects';
// import Carouselteam from '../components/Carouselteam';
// import Accomplish from '../components/Accomplish';
// import Carouselnews from '../components/Carouselnews';
import Footer from '../components/Footer';
import { Efect, Efect1, Efect2  } from "../styles/effect.styles";

const Home = ({ history }) => {


  return (
    <div>

      <Efect/>
      <Efect1/>
      <Efect2/>

      <Reveal effect="fadeIn">
        <section className="jumbotron jumbomain">
            <Slider />
        </section>
      </Reveal>
      
      <Reveal effect="fadeInUp">
        <Abouthome/>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>Our Expertnesses</div>
            </div>
            <div className='col-12'>
              <Carouselfeature/>
            </div>
          </div>
        </section>
      </Reveal>

      {/* <Reveal effect="fadeInUp">
        <section className='container-fluid pb-0'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>Our Projects</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 p-0'>
              <Carouselprojects/>
            </div>
          </div>
        </section>
      </Reveal> */}

      <Reveal effect="fadeInUp">
        <section className='container-fluid pb-0'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>Our Work</div>
            </div>
          </div>
          <div className='row m-2-hor'>            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/projects/1.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>Restaurant Design & Fit-Out</div>
                  <div className='content'>
                  We have delivered multiple
                  projects for Café /
                  Cafeterias & Restaurants with an
                  area of
                  4,000+ sqft for different clients as
                  turnkey solution
                  which includes the furniture,
                  kitchen equipment and
                  required approvals from Government & Food Control
                  Authorities.
                  </div>                  
                </div>
              </div>
            </div>            
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid pb-0'>
          <div className='row m-2-hor'>            
            <div className='col-md-6 d-none d-sm-block'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>Office Design & Fit-Out</div>
                  <div className='content'>
                  We have completed multiple office
                  fit out jobs in UAE
                  and covered area of 12,000+ sqft
                  with our prestigious
                  clients and delivered project as a
                  turnkey solution
                  which included approvals to
                  completion.
                  </div>                  
                </div>
              </div>
            </div>

            <div className='col-md-6 pt-5 d-block d-sm-none'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/projects/2.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6 pt-5 d-none d-sm-block'>
              <div className='col-home'>
                <div className='thumb22 text-right'>
                  <img
                      src="./img/projects/2.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6 d-block d-sm-none'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>Office Design & Fit-Out</div>
                  <div className='content'>
                  We have completed multiple office
                  fit out jobs in UAE
                  and covered area of 12,000+ sqft
                  with our prestigious
                  clients and delivered project as a
                  turnkey solution
                  which included approvals to
                  completion.
                  </div>                  
                </div>
              </div>
            </div>
            
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid pb-5'>          
          <div className='row m-2-hor'>            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/projects/3.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>Warehouse & Factory Design</div>
                  <div className='content'>
                  We are responsible for a wide
                  range of industrial fit
                  out projects with an area of 10,000
                  sqft which includes
                  workshops, warehouses and
                  factories with a vision to
                  add value over & above our clients
                  expectations.
                  </div>                  
                </div>
              </div>
            </div>            
          </div>
        </section>
      </Reveal>

      {/* <Reveal effect="fadeInUp">
        <section className='container-fluid'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>Our Team</div>
            </div>
            <div className='col-12'>
              <Carouselteam/>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <Accomplish/>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container-fluid pt-0'>
          <div className='row m-2-hor'>
            <div className='col-12'>
              <div className='heading'>Latest News</div>
            </div>
            <div className='col-12'>
              <Carouselnews/>
            </div>
          </div>
        </section>
      </Reveal> */}

      <Footer />

    </div>
  );
};

export default withRouter(Home);
