import React from 'react';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
    <img src='./img/footer-img.png' alt='bg-footer'/>
  </div>
    <div className='row m-2-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            {/* <h2>About Us</h2> */}
          </div>
          <div className='content'>
             <p>Our dedicated team works with you tirelessly 
             to tie your style with their design expertise, creating 
             the perfect interior design concept.</p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:info@ramadinteriors.com", "_self")}>info@ramadinteriors.com</div>
        </div>
      </div>
      {/* <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Usefull link
          </div>
          <div className='content'>
             <div className='link'>Frequently Asked</div>
             <div className='link'>Terms & Conditions</div>
             <div className='link'>Help Center</div>
             <div className='link'>Contact Us</div>
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Features
          </div>
          <div className='content'>
            <div className='link'>Career</div>
            <div className='link'>Brand Identity</div>
            <div className='link'>Investment</div>
            <div className='link'>Agency Patner</div>
          </div>
        </div>
      </div> */}
      <div className='col-md-4 offset-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            Follow Us
          </div>
          <div className='content'>
            <p>Explore thousands of inspiring interior designs, 
            from our best team here.</p>
            <div className='socialicon'>   
            <a style={{color:'inherit'}} href="https://facebook.com/alramadinteriors-106502884446324/" target="_blank" rel="noopener noreferrer">
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i></a>
            </div>
            <div className='socialicon'>
            <a style={{color:'inherit'}} href="https://www.linkedin.com/company/al-ramad-al-thahabi-interior-designing-llc/" target="_blank" rel="noopener noreferrer">
            <span className="shine"></span>
              <i className="fa fa-linkedin"></i></a>
            </div>
            {/* <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div> */}
            <div className='socialicon'>
            <a style={{color:'inherit'}} href="https://www.instagram.com/alramadinteriors/" target="_blank" rel="noopener noreferrer">
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-2-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyrights 2020 - {new Date().getFullYear()} Al Ramad Al Thahabi Interior Designing LLC All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
);
