import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';

import {
  LinkWrap,
  Overlay
} from "../styles/Work.styles";

import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Projects = ({ history }) => {
const [toCase, setCase] = useState("");
const [coord, setCoords] = useState();

useEffect(() => {
    toCase &&
      setTimeout(() => {
        history.push(toCase);
      }, 600);
  }, [toCase, history]);


  const handleCaseSwap = (e, uri) =>
    e.x < coord + 15 && e.x > coord - 15 && setCase(uri);

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>     
      <Reveal effect="fadeIn">
         <section className='jumbotron imgtop'>
         	<img
 	          src="./img/imgprojects.jpg"
 	          className="img-fluid"
 	          alt="Press"
 	        />
         </section>
      </Reveal>    

      <Reveal effect="fadeInUp">
       <section className='container-fluid pb-0 pt-0'>
          <div className='row m-2-hor'>
            <div className='col-md-12'>
              <h1 className="heading mt-5">Our Projects</h1>
            </div>
          </div>
       </section>
      </Reveal>

       <Reveal effect="fadeInUp">
       <section className='container-fluid mt-0 pt-0'>
          <div className='row m-2-hor'>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/restaurants"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/restaurants")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/p_restaurant.jpg"
                          className="img-fluid"
                          alt="Restaurants"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'></div>
                        <div className='name'>Restaurants</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Details
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/offices"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/offices")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/p_office.jpg"
                          className="img-fluid"
                          alt="Offices"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'></div>
                        <div className='name'>Offices</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Details
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/factory"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/factory")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/p_factory.jpeg"
                          className="img-fluid"
                          alt="Factory & warehouses"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'></div>
                        <div className='name'>Factory & warehouses</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Details
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/salons"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/salons")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/p_salon.jpeg"
                          className="img-fluid"
                          alt="Boutiques & Salons"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'></div>
                        <div className='name'>Boutiques & Salons</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Details
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Interior</div>
                        <div className='name'>Entertainment Unit</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Project
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase1"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase1")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img2.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Furniture</div>
                        <div className='name'>Mounted Unit</div>
                      </div>
                      <div className='icon'>
                         <span>
                          View Project
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>         */}
          </div>
       </section>
       </Reveal>

      <Footer />

    </div>
    
  );
};

export default withRouter(Projects);
