import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';

import {
  LinkWrap,
  Overlay
} from "../styles/Work.styles";

import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Press = ({ history }) => {
// const [toCase, setCase] = useState("");
// const [coord, setCoords] = useState();

// useEffect(() => {
//     toCase &&
//       setTimeout(() => {
//         history.push(toCase);
//       }, 600);
//   }, [toCase, history]);


  // const handleCaseSwap = (e, uri) =>
  //   e.x < coord + 15 && e.x > coord - 15 && setCase(uri);

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>  
      <Reveal effect="fadeIn">
         <section className='jumbotron imgtop'>
         	<img
 	          src="./img/imgpress.jpg"
 	          className="img-fluid"
 	          alt="Press"
 	        />
         </section>
      </Reveal>    

      <Reveal effect="fadeInUp">
       <section className='container-fluid pb-0 pt-0'>
          <div className='row m-2-hor'>
            <div className='col-md-12'>
              <h1 className="heading mt-5">Press</h1>
            </div>
          </div>
       </section>
      </Reveal>

       <Reveal effect="fadeInUp">
       <section className='container-fluid mt-0 pt-0'>
          <div className='row m-2-hor'>
            <div className='col-md-4 slick slickproject p-3 offset-md-4'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap>
                    <Overlay>
                      <div className='bg'>
                        <img
                          src="./img/magazine.png"
                          className="img-fluid"
                          alt="Press"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>ULTIMATE</div>
                        <div className='name'>FIT-OUT FIRMS 2022</div>
                        <div className='content pb-5'>
                        Recently, we were featured as one of the top 25 'Ultimate Fit-Out Firms 2022' in Commercial Interior Design (CID) Magazine
                        </div>
                      </div>
                      <div className='icon'>
                         <span onClick={()=>window.open('http://mags.itp.com/CommercialInteriorDesign/2022/1804-CID-APRIL2022/#page=18', '_blank')}>
                          View Details
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>            
          </div>
       </section>
       </Reveal>

      <Footer />

    </div>
    
  );
};

export default withRouter(Press);


// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import Reveal from 'react-reveal/Reveal';
// import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Footer from '../components/Footer';

// import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  
// class CustomSlide extends Component {

//   render() {
//     const { index, ...props } = this.props;
//       return (
//         <div {...props}></div>
//       );
//   }
// }

// const Press = ({ history }) => {

//   const handleSubmit=(event)=>{
//     event.preventDefault();
//     window.open('http://mags.itp.com/CommercialInteriorDesign/2022/1804-CID-APRIL2022/#page=18', '_blank');
//   }

//   var settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 5000,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false
//         }
//       },
//       {
//         breakpoint: 1600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false
//         }
//       },
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           initialSlide: 2
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   return (
    
//     <div>
//       <Efect/>
//       <Efect1/>
//       <Efect2/>

//       <div className="jumbotron head"/>

//       <Reveal effect="fadeIn">
//         <section className='jumbotron imgtop'>
//         	<img
// 	          src="./img/imgabout.jpg"
// 	          className="img-fluid"
// 	          alt="#"
// 	        />
//         </section>
//        </Reveal>
//        <Reveal effect="fadeIn">
//         <section className='container-fluid pb-0'>
// 	        <div className='row m-2-hor'>
// 	        	<div className='col-md-4'>
// 	        		<h1>Press</h1>
// 	        	</div>
// 	        	<div className='col-md-8'>
//        <div className='slick slickproject'>
//         <Slider {...settings}>

//           <CustomSlide className='itm' index={1}>
//             <div className='bg'>
//               <img
//                 src="./img/projects/img1.jpg"
//                 className="img-fluid"
//                 alt="Imageteam"
//               />
//             </div>
//             <div className='desc'>
//               <div className='tag'>Interior</div>
//               <div className='name'>Entertainment Unit</div>
//             </div>
//             <div className='icon'>
//                <span onClick={()=> window.open("http://mags.itp.com/CommercialInteriorDesign/2022/1804-CID-APRIL2022/#page=18", "_blank")}>
//                 View Project
//               </span>
//             </div>
//           </CustomSlide>
//         </Slider>
//       </div>
//       </div>
//       </div></section></Reveal>
// {/* 
//       <Reveal effect="fadeIn">
//         <section className='container-fluid pb-0'>
// 	        <div className='row m-2-hor'>
// 	        	<div className='col-md-4'>
// 	        		<h1>Press</h1>
// 	        	</div>
// 	        	<div className='col-md-8'>
// 	        		<div className='content'>Featured as one of the top 25 'Ultimate Fit-Out Firms 2022' in Commercial Interior Design (CID) Magazine
// 	        		</div>
             
//               <div className="form-side">
//                 <form className="formcontact" onSubmit={handleSubmit}>
//                  <button type="submit" id='buttonsent'>
//                     <span className="shine"></span>
//                     <span>Send</span>
//                   </button>
//                 </form>
//               </div>
// 	        	</div>
            
// 	        </div>
// 	    </section>
// 	   </Reveal> */}
//       <Footer />

//     </div>
    
//   );
// };

// export default withRouter(Press);
