import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  Body,
  Page,
  LinkTag,
  SocialContainer,
  Overlaybg
} from "../styles/Navigation.styles";
import { withRouter } from "react-router";
import NavButton from "./NavButton";

const NavigationMenu = ({ history, hasBackground, setBackground }) => {
  const [isOn, setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [linking, setLink] = useState("");

  useEffect(() => {
    !!linking &&
      setTimeout(() => {
        switch (linking) {
          case "home":
            history.push("/");
            setState(false);
            setLink("");
            break;
          case "about":
            history.push("/about");
            setState(false);
            setLink("");
            break;
          case "projects":
            history.push("/projects");
            setState(false);
            setLink("");
            break;
          case "press":
            history.push("/press");
            setState(false);
            setLink("");
            break;
          case "contact":
            history.push("/contact");
            setState(false);
            setLink("");
            break;
          default:
            setLink("");
        }
        setBackground(false);
        window.scrollTo(0, 0);
      }, 0);
  }, [linking, history, setBackground]);

  useEffect(() => {
    shouldAnimate &&
      !isOn &&
      setTimeout(() => {
        setShouldAnimate(false);
      }, 0);
  }, [shouldAnimate, isOn]);

  const closeHandler = () => {
    setShouldAnimate(true);
    setState(!isOn);
  };

  const setLinkHandler = text => {
    setShouldAnimate(true);
    setLink(text);
  };

  useEffect(() => {
    const header = document.getElementById("header");
    const totop = document.getElementById("scroll-to-top")
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
    if (window.pageYOffset > sticky + 0) {
      header.classList.add("sticky");
      totop.classList.add("show");
    } else {
      header.classList.remove("sticky");
      totop.classList.remove("show");
    } 
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
    }, []);

  return (
    <header>
      <div id='header'></div>
      <div className='logo d-none d-sm-block'>
        <img
          src="./img/logowhite.png"
          className="img-fluid"
          alt="Al Ramad Al Thahabi Interior Designing LLC"
          onClick={() => setLinkHandler("home")}
        />        
      </div>
      <div className='logo22 d-block d-sm-none'>
        <img
          src="./img/logowhite.png"
          className="img-fluid"
          alt="Al Ramad Al Thahabi Interior Designing LLC"
          onClick={() => setLinkHandler("home")}
        />        
      </div>
      <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
        <Overlaybg open={isOn} shouldAnimate={shouldAnimate} onClick={closeHandler}/>
        <Container
          open={isOn}
          onClick={closeHandler}
          hasBackground={hasBackground}>
          <NavButton open={isOn} />
        </Container>
        <Body className='midwrpr' open={isOn} shouldAnimate={shouldAnimate}>
          <div className='conPage'>
              <Page className='mainBtn' variant="home" onClick={() => setLinkHandler("home")}>
                  <LinkTag>Home</LinkTag>
              </Page>
              <Page className='mainBtn' variant="about" onClick={() => setLinkHandler("about")}>
                  <LinkTag>About</LinkTag>
              </Page>
              <Page className='mainBtn' variant="press" onClick={() => setLinkHandler("press")}>
                  <LinkTag>Press</LinkTag>
              </Page>
              <Page className='mainBtn' variant="work" onClick={() => setLinkHandler("projects")}>
                  <LinkTag>Projects</LinkTag>
              </Page>              
              <Page className='mainBtn' variant="contact" onClick={() => setLinkHandler("contact")}>
                  <LinkTag>Contact</LinkTag>
              </Page>
          </div>

          <div className='info'>
            <span className='link'><a style={{color:"inherit",textDecoration:"None"}} href="tel:+97165200441">(+971) 65 20 0441</a></span>
            <span className='link'><a style={{color:"inherit",textDecoration:"None"}} href="tel:+971545948669">(+971) 54 594 8669</a></span>
            <span className='link'><a style={{color:"inherit",textDecoration:"None"}} href='mailto:info@ramadinteriors.com' target='_blank' rel='noopener noreferrer'>info@ramadinteriors.com</a></span>
          </div>

        </Body>
        <SocialContainer className='soc-icon' open={isOn}>
            <span>Follow us:</span>
            <a style={{color:"inherit"}} href="https://facebook.com/alramadinteriors-106502884446324/" target="_blank" rel="noopener noreferrer"><span className='socicon'><i className="fa fa-facebook-f"></i></span></a>
            <a style={{color:'inherit'}} href="https://www.linkedin.com/company/al-ramad-al-thahabi-interior-designing-llc/" target="_blank" rel="noopener noreferrer"><span className='socicon'><i className="fa fa-linkedin"></i></span></a>
            {/* <span className='socicon'><i className="fa fa-twitter"></i></span> */}
            <a style={{color:"inherit"}} href="https://www.instagram.com/alramadinteriors/" target="_blank" rel="noopener noreferrer"><span className='socicon'><i className="fa  fa-instagram"></i></span></a>
        </SocialContainer>
      </Wrapper>
    </header>
  );
};

export default withRouter(NavigationMenu);
