import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Restaurants extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/projects");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "url('./img/projects/restaurant/img1.jpg')" }}>
              <div className='herocaption'>
                <BackButton className='backdetail'
                  onClick={() => this.setState({ toBack: true, hasBackground: false })}
                  toBack={this.state.toBack}
                  hasBackground={this.state.hasBackground}>
                  <BackArrow src="./img/back.png" alt="Back to Projects" />
                  <span>Back </span>
                </BackButton>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <Title>Restaurants</Title>                      
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>We have delivered multiple projects for Café / Cafeterias & Restaurants with an area of​ 4,000+ sqft for different clients as turnkey solution which includes the furniture, kitchen equipment and​ required approvals from Government & Food Control Authorities.​</p>              
              <div className='single-img max-height500'>
                <img
                  src="./img/projects/restaurant/img2.jpeg"
                  className="img-fluid"
                  alt="Restaurant"
                />
              </div>              
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/projects/restaurant/img6.png"
                  className="img-fluid"
                  alt="Restaurant"
                />
              </div>
              <div className='single-img max-height300 pimg-5'>
                <img
                  src="./img/projects/restaurant/img4.png"
                  className="img-fluid"
                  alt="Restaurant"
                />
              </div>
            </div>
            <div className='col-md-6 p-md-0'>
              <div className='single-img pimg-5'>
                <img
                  src="./img/projects/restaurant/img12.jpg"
                  className="img-fluid"
                  alt="Restaurant"
                />
              </div>
            </div>            
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Restaurants);
